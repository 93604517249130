<template>
    <div class="Contact">
        <div class="News-wrap">
            <h3>专业游艇码头制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/News' }">联系方式</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="contact_wrap">
            <h1>联系方式</h1>
            <div class="contact_box">
                <h3>开平经祥港口设施工程有限公司</h3>
                <div class="contact_text">
                    <p>地址：开平市翠山湖新区环翠西路12号一楼</p>
                    <!-- <p>客服热线：0750-2331903</p> -->
                    <p>网址（WEB)：<a href="www.bestdock.cn">www.bestdock.cn</a></p>
                    <p>电话：199 2592 8565 </p>
                    <p>邮箱：yannickhe@bestdock.cn</p>
                    <p>微信：yannickhe</p>


                </div>
            </div>

            <div class="contact_box">
                <h3>KaiPing Bestdock Company Limited</h3>
                <div class="contact_text">
                    <p>Add：First floor, No. 12, Huancui West Road, cuishanhu new area, Kaiping City,GuangDong</p>
                    <!-- <p>客服热线：0750-2331903</p> -->
                    <p>WEB：<a href="www.bestdock.cn">www.bestdock.cn</a></p>
                    <p>Tel：+86 199 2592 8565 </p>
                    <p>Email：yannickhe@bestdock.cn</p>
                    <p>Wechat：yannickhe</p>


                </div>
            </div>

            <!-- <div class="baidumap" id="allmap"></div> -->
            <!-- 地图的容器 -->


        </div>
        <div class="Case_wrap">
            <section class="destination" id="destination">

                <div class="heading">
                    <h1>精美案例</h1>
                    <span>为您带来更多的可能性</span>
                    
                </div>

                <div class="box-container">


                    <div class="box" data-aos="fade-up" data-aos-delay="450">
                        <router-link to="/Case">
                        <div class="image">
                            <img src="../assets/images/des-3.jpg" alt="">
                        </div>
                        <div class="content">
                            <h3>国外项目</h3>
                            <p>2020-06<br>卡塔尔铝合金码头<br>案例</p>
                            <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                        </div>
                        </router-link>
                    </div>

                    <div class="box" data-aos="fade-up" data-aos-delay="600">
                        <router-link to="/Case">
                        <div class="image">
                            <img src="../assets/images/des-4.jpg" alt="">
                        </div>
                        <div class="content">
                            <h3>国外项目</h3>
                            <p>2018-10 <br>迪拜码头<br>案例</p>
                            <a href="#">了解更多<i class="fas fa-angle-right"></i></a>
                        </div>
                        </router-link>
                    </div>

                    <div class="box" data-aos="fade-up" data-aos-delay="750">
                        <router-link to="/Case">
                        <div class="image">
                            <img src="../assets/images/des-5.jpg" alt="">
                        </div>
                        <div class="content">
                            <h3>国内案例</h3>
                            <p>2021-05<br>福建漳州沙滩网红冲浪浮桥<br>案例</p>
                            <a href="#">了解更多 <i class="fas fa-angle-right"></i></a>
                        </div>
                        </router-link>
                    </div>



                </div>

            </section>
        </div>

        <!-- banner section star-ts  -->

        <!-- <div class="banner">

            <div class="content" data-aos="zoom-in-up" data-aos-delay="300">
                <span>富升实业</span>
                <h3>专业浮动码头制造商</h3>
                <p>富升实业有限公司是一家专业从事于游艇码头，浮动码头，浮桥，浮动防波提，光伏浮筒及配套设施的企业，也是一家集研发、设计、销售、生产、施工、维护为一体化的专业公司。本公司拥有一支从业经验超过10年的专业团队，积累了丰富的水上工程经验和技术。
                </p>
                <a href="#book-form" class="btn">联系我们</a>
            </div>

        </div> -->

        <!-- banner section ends -->
    </div>
</template>

<script>
    export default {
        name: 'pm-distribution',
        components: {

        },
        mounted() {

            this.baiduMap()
        },
        methods: {
            // baiduMap() {

            //     this.map = new window.BMap.Map("allmap"); // 创建地图实例

            //     let point = new window.BMap.Point(112.651093,22.43769); // 创建点坐标
            //     this.map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
            //     this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

            //     this.map.addControl(new window.BMap.NavigationControl());
            //     this.map.addControl(new window.BMap.ScaleControl());
            //     this.map.addControl(new window.BMap.OverviewMapControl());
            //     // this.map.addControl(new BMap.MapTypeControl());
            //     //map.setMapStyle({ style: 'midnight' }) //地图风格

            //     let pointList = [{
            //         name: "开平经祥港口设施工程有限公司",
            //         id: 1,
            //         info: "提示信息1",
            //         lat: "112.651093",
            //         lot: "22.43769",
            //     }, ];
            //     for (let i = 0; i < pointList.length; i++) {
            //         let point = new window.BMap.Point(pointList[i].lat, pointList[i].lot);
            //         let marker = new window.BMap.Marker(point); // 创建标注
            //         this.map.addOverlay(marker); // 将标注添加到地图中
            //     }
            // }
        }
    }
</script>

<style>
    .Contact section{
        padding: 3rem 9%;
    }

    .contact_wrap {
        max-width: 1200px;
        margin: 0 auto;
    }

    .contact_wrap h1 {
        position: relative;
        font-size: 34px;
        font-weight: normal;
        margin: 10px auto;
        width: 400px;
        color: #000;
    }

    .contact_wrap h1::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 1px;
        background: rgb(163, 163, 163);
        top: 50%;
        left: 0;
    }

    .contact_wrap h1::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 1px;
        background: rgb(163, 163, 163);
        top: 50%;
        right: 0;
    }

    .contact_box {
        text-align: left;
    }

    .contact_box h3 {
        font-size: 24px;
        margin: 30px 0;
        font-style: italic;
        color: #000;
    }

    .contact_box .contact_text p {
        font-size: 14px;
        margin-top: 10px;
        color: #222;
    }
    

    .baidumap {
        margin: 40px 0;
        width: 1000px;
        height: 500px;
        border: 1px solid #eee;
        /* position: absolute; */
        /* left: 0;
        top: 0;
        right: 0;
        bottom: 0; */
        /* margin: auto; */
    }

    /* 去除百度地图版权那行字 和 百度logo */
    .baidumap>.BMap_cpyCtrl {
        display: none !important;
    }

    .baidumap>.anchorBL {
        display: none !important;
    }

    @media screen and (max-width:768px) {
        .contact_wrap h1 {
            width: 100%;
        }

        .contact_wrap {
            margin: 15px;
        }

        .contact_wrap h2 {
            width: 100%;
        }

        .baidumap {
            width: 100%;
        }
    }
</style>