<template>
    <div class="product-1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">产品</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="title-wrap">
            <div class="title">
                <h3> Floating Solar Plants<br>水上光伏平台</h3>
            </div>
            <div class="img-wrap">
                <img src="../assets/Product/8.jpg" alt="">
            </div>
            <div class="content-wrap">
                <h2> Floating Solar Plants<br>
                    水上光伏平台<br><br></h2>

                <p>水上光伏平台<br>
                    打造生态环保、可靠高效的水面光伏系统，致力于提供多种水域适用的一站式光伏漂浮系统解决方案。
                    光伏漂浮系统主要分为三种方案：系统安装方案、系统锚固方案、系统排布方案 。
                    市场前景：目前水面光伏更好的节约土地资源，将未使用的水上空间转化为可创造利益的区域，更好的孵化环境，提高水产量。并
                    且无需大规模基础设施，模块化结构易于组装，四周无遮挡物运维更加方便，施工周期短将减少成本。同时水体具有自然冷却作用
                    ，可对系统进行冷却。水冷通风效果好，进一步提高发电量，延长使用寿命。<br><br>

                    Floating Solar: create an eco-friendly, reliable and efficient floating solar system,and provide
                    variety floating system
                    solutions.<br>
                    Floating solar system is mainly divided into three part: installation , anchorage and
                    layout.<br><br>
                    Market prospect: At present, floating solar system can better save land resources, transform unused
                    water space into
                    an area that can create benefits, better incubation environment and improve aquatic production.
                    Moreover, there is no need for large-scale infrastructure, the modular structure is easy to
                    assemble. There is no shelter
                    around, which is more convenient for operation and maintenance, short construction period will
                    reduce costs. At the
                    same time, the water body has the function of natural cooling, which can cool the system. The effect
                    of water cooling
                    and ventilation is good, which further improves the power generation and prolongs the service life.
                    <br><br>

                    产品优势 Product Advantages<br>
                    •食品级HDPE材料，环境友好<br>
                    •通过RoHS有害物质检测认证<br>
                    • Food grade HDPE material, environment friendly<br>
                    • Passed RoHS hazardous substance testing and certification.<br><br>

                    •通过材料的无害化检测及认证<br>
                    •抗老化、抗风浪性能强<br>
                    • Passed the material harmless testing and certification<br>
                    • Ageing resistance, anti-wind and wave<br><br>

                    •集中承载力强，耐穿刺<br>
                    •抱耳抗拉抗剪性能强，连接防松<br>
                    • Concentrated bearing capacity and puncture resistance<br>
                    • The lug has strong tensile and shear resistance, and anti
                    loose connection.<br><br>

                    •水密性好，表面防滑，底部耐刮擦<br>
                    •防渗漏耐水解 抗光氧湿热老化<br>
                    •长耐候材料，25年设计寿命<br>
                    • Good water tightness, anti-skid surface and scratch
                    resistance at the bottom<br>
                    • Anti-leakage, hydrolysis resistance, photo-oxygen
                    moisture-heat resistance<br>
                    • Long weather resistant material with 25 years life span
                </p>
            </div>
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in items" :key="item.id">
                        <img :src="item.img" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>

        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [{
                        id: '01',
                        img: require('../assets/Case/Float/2/1.jpg')
                    },
                    {
                        id: '02',
                        img: require('../assets/Case/Float/2/2.jpg')
                    },
                    {
                        id: '03',
                        img: require('../assets/Case/Float/2/3.jpg')
                    },
                    {
                        id: '04',
                        img: require('../assets/Case/Float/2/4.jpg')
                    },
                    {
                        id: '05',
                        img: require('../assets/Case/Float/2/5.jpg')
                    },
                    {
                        id: '06',
                        img: require('../assets/Case/Float/2/6.jpg')
                    },
                ],
            }
        },
    }
</script>

<style src='../assets/css/Product.css'>

</style>