var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Product-Center"},[_c('div',{staticClass:"Center-banner"},[_c('div',{staticClass:"Center-title"},[_vm._m(0),_vm._m(1),_c('router-link',{attrs:{"to":"/Case"}},[_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_vm._v("了解更多/More")])])],1)]),_c('div',{staticClass:"Center-wrap"},[_c('section',{staticClass:"services",attrs:{"id":"services"}},[_vm._m(2),_c('div',{staticClass:"box-container"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"box",attrs:{"data-aos":"zoom-in-up","data-aos-delay":"150"}},[_c('router-link',{attrs:{"to":'/' + item.router}},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',{staticClass:"content"},[_c('i',{staticClass:"fas fa-globe"}),_c('h3',[_vm._v(_vm._s(item.nameC))]),_c('p',[_vm._v(_vm._s(item.nameE))])])])],1)}),0)]),_c('section',{staticClass:"about",attrs:{"id":"about"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"aboutus-wrapper d-flex"},[_vm._m(3),_c('div',{staticClass:"aboutus-content"},[_c('h2',[_vm._v("关于产品")]),_vm._m(4),_c('p',[_vm._v("本公司已有十二年专业工作经验，对于水上浮码头工程，水上钢、铝结构专业加工及施工，水利土建工程保护所需水上隔离带等等.....")]),_vm._m(5),_c('router-link',{attrs:{"to":"/Case"}},[_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_vm._v("了解更多/More")])])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("产品中心/ "),_c('p',[_vm._v("Introduction")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("本公司已有十二年专业工作经验对于水上浮码头工程，水上钢、铝结构专业加工及施工水利土建工程保护所需水上隔离带等等..... "),_c('br'),_vm._v("Bestdock has more than 12 years of experience and is constantly expanding . Our team offers expert consulting, design, manufacturing and construction services.Our technical and production expertise in the field of floating docks enables us to minimize the cost and duration of the manufacturing process while maintaining the quality and safety of our products. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading"},[_c('span',[_vm._v("产品案例")]),_c('h1',[_vm._v("专业团队为您服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutus-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/des-1.jpg"),"alt":"about image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 浮筒： 组合式水上浮动平台组装简易、快速,造型多样化,可依需要而变化,组合成任意形状、任意大小的浮动平台。"),_c('br'),_vm._v(" 游艇码头：采用6061海事铝合金结构 / 热镀锌钢结构，具有让游艇停泊，水电供应，清洗，维修和游人上下船等功能。"),_c('br'),_vm._v(" 船艇泊位: 安装拆卸方便、实用美观、自由组合，可以停放不同型号的的船只。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br'),_vm._v("Modular pontoon: Our high quality modular floating system is a truly revolutionary product; it’s environmentally friendly, and requires no maintenance. Easy to use and reliable, it provides unlimited configuration and application options."),_c('br'),_c('br'),_vm._v("Aluminum /steel pontoon: The main structure of the frame construction consists of anticorodal marine grade aluminium 6061-T6 / Hot dip galvanized steel . It has the functions of berthing yachts, water and electricity supply, cleaning, maintenance and tourists getting on and off the ship"),_c('br'),_c('br'),_vm._v("Boat berth: When stored in the water, jet ski hulls can suffer from delamination and intake fouling. With our floating jet ski docks and boat dock, you'll save your craft from undue wear and tear and improve ease of use. "),_c('br'),_c('br'),_vm._v("Acrdock has more than 12 years of experience and is constantly expanding. We offer aluminium and steel pontoons, breakwaters, hdpe modular pontoons and other marina equipments. ")])
}]

export { render, staticRenderFns }