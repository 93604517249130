<template>
    <div class="product-1">
        <div class="News-wrap">
            <h3>专业游艇码头 制造商<br>Professional Floating Dock Manufacturer</h3>
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/Case' }">产品</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>

        <div class="title-wrap">
            <div class="title">
                <h3>DEEP SEA FISHING CAGES<br>新型深海渔排</h3>
            </div>
            <div class="img-wrap">
                <img src="../assets/Product/6.jpg" alt="">
            </div>
            <div class="content-wrap">
                <h2>DEEP SEA FISHING CAGES<br>
                    新型深海渔排<br><br></h2>

                <p>The new connecting construction for a plastic fishing row constructing with another finshing row. The
                    connecting construction is composed of a horizontal
                    pipe and a longitudinal pipe,and the connecting construction part is produced by injection,so that
                    it has high structural strength.This new plasic fishing
                    raft has the components which has precision,structural stability,reliability and long service life.<br><br>

                    是一种新型塑胶渔排用连接构件以及使用该构件的塑胶渔排，该连接构件由横管以及纵管构成，连接构件采用一次注塑成型，具有较高的结构强度。新
                    型塑胶渔排构件精度、结构稳定性、使用可靠性以及使用寿命长。
                    该网箱具有稳定性强、抗风浪、抗老化、抗扭曲、耐碰击、水流通畅、养殖密度大、养殖单产高等特点，成活率比现有木箱养殖提高约20%，节省海域
                    面积30%以上。采用新型养殖设备开展养殖，能够提高水产养殖品质，保证产品质量。
                </p>
            </div>
            <section class="gallery" id="gallery">
                <div class="box-container">

                    <div class="box" v-for="item in items" :key="item.id">
                        <img :src="item.img" alt="">
                        <!-- <span>2020.06 </span>
                    <h3>澳大利亚-Tom快艇码头</h3> -->
                    </div>

                </div>

            </section>

        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [{
                        id: '01',
                        img: require('../assets/Case/Ocean/2/1.jpg')
                    },
                    {
                        id: '02',
                        img: require('../assets/Case/Ocean/2/2.jpg')
                    },
                    {
                        id: '03',
                        img: require('../assets/Case/Ocean/2/3.jpg')
                    },
                    {
                        id: '04',
                        img: require('../assets/Case/Ocean/2/4.jpg')
                    },
                    {
                        id: '05',
                        img: require('../assets/Case/Ocean/2/5.jpg')
                    },
                    {
                        id: '06',
                        img: require('../assets/Case/Ocean/2/6.jpg')
                    },


                ],
            }
        },
    }
</script>

<style src='../assets/css/Product.css'>

</style>