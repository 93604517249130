<template>
    <div class="news1">
        <div class="News-wrap">
            <h3>专业游艇码头制造商<br>Professional Floating Dock Manufacturer</h3>
           
        </div>

        <div class="navigation-wrap">

            <div class="navigation-bar">
                <div class="navigation-left-bar">
                    <p>您的位置：</p>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/News' }">新闻资讯</el-breadcrumb-item>
                        <el-breadcrumb-item>塑料浮筒</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>

        </div>

        <div class="news_content">
            <div class="news_content_box">
                <div class="content_title">
                    <h3>珠海市塑料浮筒海上游艇码头</h3>
                </div>
                <div class="content_date">
                    <p>发布时间：2022-01-31 17:35</p>
                    <span>新闻标签：水上休闲平台</span>
                </div>
            </div>

            <div class="news_content_text">
                <p>前言：何为水上休闲平台？
                    <br>是指无动力或有动力的，以餐饮娱乐、观光旅游、度假旅游为目的的，延伸到水面上的平台结构。水上休闲平台包括两大类：一类是漂浮式平台，一类是突堤码头固定式平台。水上休闲平台是滨海、滨湖、滨江地区新兴的滨水休闲娱乐产品。主要产品涉及：水上平台、游艇码头、水上浮桥、水上休闲平台、快艇码头、浮动码头、观光亲水平台、水上餐厅、水上游乐园、水上木屋、水上舞台、海上浴场、水上泳池、轮渡、网箱养鱼和水上娱乐设施等一系列水上工程项目。
                    <br>
                    <br>一、最原始的水上休闲平台——鱼排
                    <br>渔排的形成是渔民出于生存目的，必须出海捕鱼，出海捕鱼同时还要承担各类的风险，因此渔排诞生了，渔民在海上挑选好自己所要定居下来的海面，随后用橡胶渔排，制成方格状，在方格里系网进行水产品养殖，而在养殖地上同时也修建1个小屋子供渔民生活所需，也有渔民吃住都在这座小屋子。
                </p>
                <br>
                <img src="../assets/img/product3.jpg" alt="">
                <br>
                <p>用以滨水休闲度假的鱼排主要用途是特色餐饮、休闲垂钓、观光旅游等。在海南省陵水县新港有疍家小渔村。新村港湾上密集飘浮着幢幢小木屋，小木屋四侧海面围合着一个一个木制网格、呈井田式规则排序的养殖网箱——这就是当地人俗称的“渔排”。渔排沿航道绵延数里，如水上城市一般;而浅海滩涂养殖围地的道道竹篱和参差的倒影，又使整个港湾如同水上田园。这就是陵水的“海上威尼斯”——疍家渔排。
                    <br>
                    <br>渔排居民“疍家人”是1个特殊的群落。疍民历史最早可追溯到秦汉古越族。相传在乾隆年间，闽粤两地疍家人划过波涛汹涌的琼州海峡，落户海南岛。他们以海为生，以船为家，长年过着飘泊海上的生活，“世世水为乡，代代舟为家;沉浮江海里，世代海江南”是老一辈疍家人生活状况的写照。
                    <br>
                    <br>陵水疍民在驻留也已有500多年历史。他们的老祖先大多数来于福建泉州，也有广东南海、佛山顺德等地，以舟楫为家，靠捕鱼为生，虽经历史变迁却仍留存着与别的民族迥然不同的民俗文化，“疍家人”有自己的语言，近于粤语。
                </p>
            </div>

            <div class="content_page">
                <router-link to="" class="last_page">上一篇：没有了</router-link>
                <router-link to="" class="next_page">下一篇：没有了</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>
   .news_content_text img{
       width: 800px;
   }

    @media screen and (max-width:768px) {
        .news_content{
            margin: 15px;
        }

        .news_content_text img{
            width: 100%;
            height: 100%;
        }
    }
</style>