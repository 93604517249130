var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-1"},[_vm._m(0),_c('div',{staticClass:"navigation-wrap"},[_c('div',{staticClass:"navigation-bar"},[_c('div',{staticClass:"navigation-left-bar"},[_c('p',[_vm._v("您的位置：")]),_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Case' }}},[_vm._v("产品")])],1)],1)])]),_c('div',{staticClass:"title-wrap"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('section',{staticClass:"gallery",attrs:{"id":"gallery"}},[_c('div',{staticClass:"box-container"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"box"},[_c('img',{attrs:{"src":item.img,"alt":""}})])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"News-wrap"},[_c('h3',[_vm._v("专业游艇码头 制造商"),_c('br'),_vm._v("Professional Floating Dock Manufacturer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("JET SKI DOCK/BOAT DOCK"),_c('br'),_vm._v("摩托艇快艇码头")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('p',[_vm._v("摩托艇快艇码头："),_c('br'),_c('br'),_vm._v(" 摩托艇/快艇码头全部由塑胶为原料制造，独特的干泊位设计，可以将摩托艇或其它船只直接开上码头。避免船只因长期停留在水中而产生污点、生锈、生长藻类、腐蚀等对船外壳的伤害。拥有一组摩托艇、快艇码头，不单可以减少对船只的损坏而且不用任何维护费用。安装拆卸方便、实用美观、自由组合，可以停放不同型号的的船只。摩托艇码头可以任意设计不同尺寸的走道，方便上下船只或者在码头上清洗、维修船只。"),_c('br'),_c('br'),_vm._v(" JET SKI DOCK/BOAT DOCK"),_c('br'),_c('br'),_vm._v(" When stored in the water, jet ski hulls can suffer from delamination and intake fouling. With our floating jet ski docks and boat dock, you'll save your craft from undue wear and tear and improve ease of use. Featuring an innovative design, you can add or reconfigure pontoons as you need. This floating dock allows you to drive on and off in seconds! Built with users in mind, Acrdock features ample walk-around space to make it easy for you to board, unboard, and launch your jet ski instantly. Made with high-density polyethylene materials, our floating pontoon are lightweight, durable, easy to install with simple cleats and connectors, and don’t require any special tools or technical skills. "),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/Product/3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('h2',[_vm._v("JET SKI DOCK/BOAT DOCK"),_c('br'),_vm._v(" 滑道快艇码头"),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/Product/4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('h2',[_vm._v("JET SKI DOCK/BOAT DOCK"),_c('br'),_vm._v(" V型浮筒快艇码头"),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/Product/5.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[_c('h2',[_vm._v("JET SKI DOCK/BOAT DOCK"),_c('br'),_vm._v(" 滚轮浮筒快艇码头"),_c('br'),_c('br')])])
}]

export { render, staticRenderFns }